import React from "react";
import '../styles/settings.scss'
import {useState,useEffect} from 'react'
import Switch from 'react-ios-switch';
const Settings = ({handleClose}) => {
  const [analytics, setAnalytics] = useState(true)
  const [uncategorized, setUncategorized] = useState(true)
  const [performance, setPerformance] = useState(true)
  const [advertisement, setAdvertisement] = useState(true)
  useEffect(() => {
    
    if(!localStorage.getItem('analytics')){
      localStorage.setItem('analytics',true)
    }
    else{
      setAnalytics(JSON.parse(localStorage.getItem("analytics")))
    }
    if(!localStorage.getItem('uncategorized')){
      localStorage.setItem('uncategorized',true)
    }
    else{
      setUncategorized(JSON.parse(localStorage.getItem("uncategorized")))
    }
    if(!localStorage.getItem('performance')){
      localStorage.setItem('performance',true)
    }
    else{
      setPerformance(JSON.parse(localStorage.getItem("performance")))
    }
    if(!localStorage.getItem('advertisement')){
      localStorage.setItem('advertisement',true)
    }
    else{
      setAdvertisement(JSON.parse(localStorage.getItem("advertisement")))
    }
  },[])
  function saveSettings(){
    localStorage.setItem('analytics',analytics)
    localStorage.setItem('uncategorized',uncategorized)
    localStorage.setItem('performance',performance)
    localStorage.setItem('advertisement',advertisement)
    handleClose()

  }
 

    return(
  <div className="privacy-settings px-3 py-3">
    <div className="d-flex justify-content-between">
      <div><h6>Privacy Overview</h6></div>
     
      <div><a aria-label="close" href={() => false} className="close" onClick={handleClose}><img alt="close" width={20} height={20} src="/close.png"/></a></div>
      

    </div>
    <div className="mt-2 content">This website uses cookies to improve your experience while you navigate through the website.
    Out of these cookies, the cookies that are categorized as necessary are stored on your browser as
    they are essential for the working of basic functionalities.
    </div>
    <div className="cookie-option mt-3 d-flex justify-content-between align-items-center py-2 px-2">
      <div className="cookie-name">Necessary</div>
      <div className="enable align-self-center">Always Enabled</div>

    </div>
    <div className="cookie-option mt-2 d-flex justify-content-between align-items-center py-2 px-2">
      <div>Analytics</div>{analytics}
      <div className="d-flex flex-row ">
      {analytics===true?<div className="pe-2 align-self-center enable">Enabled</div>:<div className="pe-2 align-self-center enable">Disabled</div>}
      <Switch
        checked={analytics}
        className="switch"
        onChange={() => setAnalytics(!analytics)}
      />
  

      </div>

    </div>
    <div className="cookie-option mt-2 d-flex justify-content-between align-items-center py-2 px-2"> 
      <div>Uncategorized</div>
      <div className="d-flex flex-row ">
        {uncategorized===true?<div className="pe-2 align-self-center enable">Enabled</div>:<div className="pe-2 align-self-center enable">Disabled</div>}
       
        <Switch
        checked={uncategorized}
        className="switch"
        onChange={() => setUncategorized(!uncategorized)}
      />
      </div>

    </div>
    <div className="cookie-option mt-2 d-flex justify-content-between align-items-center py-2 px-2">
      <div>Performance</div>
      <div className="d-flex flex-row ">
      {performance===true?<div className="pe-2 align-self-center enable">Enabled</div>:<div className="pe-2 align-self-center enable">Disabled</div>}
      <Switch
        checked={performance}
        className="switch"
        onChange={() => setPerformance(!performance)}
      />
      </div>

    </div>
    <div className="cookie-option mt-2 d-flex justify-content-between align-items-center py-2 px-2">
      <div>Advertisement</div>
      <div className="d-flex flex-row ">
      {advertisement===true?<div className="pe-2 align-self-center enable">Enabled</div>:<div className="pe-2 align-self-center enable">Disabled</div>}
      <Switch
        checked={advertisement}
        className="switch"
        onChange={() => setAdvertisement(!advertisement)}
      />
      </div>
    </div>
    <div className="my-3 text-end"><button onClick={saveSettings} className="cookie-save-btn px-3 py-2">Save & Accept</button></div>
    
    
  </div>
    )
};
export default Settings;
