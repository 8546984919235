import * as React from "react"
import PropTypes from "prop-types"
import {Helmet} from 'react-helmet'
import loadable from '@loadable/component'
import Footer from "./footer"
import '../styles/layout.scss'
import Banner from "./banner"
import {useState} from 'react'
const Header = loadable(() => import('./header'))

const Layout = ({ children }) => {
  const [isToggle, setIsToggle] = useState(false);

  const  navtoggle= (Toggle) => {
   setIsToggle(Toggle)
  }
 
  return (
    <>
     
      <Header navtoggle={navtoggle}/>
     <div id="blur" className={`${isToggle ? "blur-effect" : ""}`}  >

    
      <div id="main">
          <main >{children}</main>
     
        <Helmet>
        <html lang='en' />
        <title>Olikaone</title>
        <meta name="description" content="Digital Media Company | Creating a Unique iGaming Experience With Innovative Partnerships." />
        <link rel="icon" href="/favicon32x32.png"/>
          <link rel="apple-touch-icon" href="/favicon180x180.png"/>
          <link rel="preconnect" href="https://fonts.gstatic.com"/>
          <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
          <link href="https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet"></link>
          <script src='https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-alpha2/dist/js/bootstrap.bundle.min.js'></script>
          <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
          <script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" async defer></script>
        
        </Helmet>
      <Footer/>
      </div>
      <Banner/>
    </div>
    
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
