import React from 'react'
import '../styles/banner.scss'
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';
import {useState,useEffect} from 'react'
import Settings from '../components/Settings'
import { navigate } from 'gatsby';


const Banner = () => {
    const [cookie, setCookie] = useState(true)
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
       
        if(Cookies.get('olika_cookie')){
            setCookie(false)
        }
        var ele=document.getElementById('main')
        if(isOpen===true){
           
            if(ele!==undefined){
                ele.classList.add('blur-effect')
            }
           

        }
        else{
            if(ele!==undefined){
                ele.classList.remove('blur-effect')
            }
            ele.classList.remove('blur-effect')
        }
    },[isOpen])
    function accept(){
        setCookie(false)
    }
    function privacy(){
        navigate('/privacy/');

    }
    const togglePopup = () => {
        setIsOpen(!isOpen);
        
    }
  
    return (
        <>
        {cookie === true?
        <div className="d-flex flex-wrap  align-items-center flex-wrap justify-content-md-center justify-content-start banner py-2">
            <div className="d-flex flex-column banner-text">
                <div className="privacy">We Value Your Privacy</div>
                <div className="cookie-text">We and our trusted third-party partners use cookies and other technologies to enhance site security, to improve and personalise your experience. For additional details view our <a href="/privacy" onClick={privacy}>Privacy and Cookies Statement</a>.</div>
            </div> 
            <div className="cookie-btn" >
                <CookieConsent
                    onAccept={accept}
                    buttonText="Accept"
                    contentClasses="content"
                    buttonClasses="accept-btn"
                    cookieName="olika_cookie"
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                    >
                </CookieConsent>
            </div>
            <div className="manage">
            <button onClick={togglePopup} className="manage-btn">Manage Settings</button>
            {isOpen && <Settings
                    handleClose={togglePopup}
            />}
           
            </div>
       </div> :null}
       </>
    )
}
export default Banner