import React from 'react'
import '../styles/footer.scss'
import {useEffect,useState} from 'react'
import axios from 'axios'
import { navigate } from 'gatsby';


export default function Footer() {
    const [weather, setweather] = useState('');
    const [icon, seticon] = useState('');
    useEffect(() => {
     
      
        axios.get("https://olika.redx.online/api/weather/")
		.then(response => {
            setweather(Math.floor(response.data[0].temperature))
            seticon(response.data[0].icon)
		})
		.catch(function (error) {
            console.log(error)
			
		});
        
        
    },[])
    function privacy(){
        navigate('/privacy/');
    }
    return (
        <div className="container footer">

            <div className="d-flex flex-md-row flex-column align-items-md-center align-items-left justify-content-between mt-5">
                <div className="d-flex  item-left order-md-0 order-1">
                    <div className="d-flex flex-column">
                        <h6 className="footer-text">Connect with us</h6>
                        <a rel="noopener noreferrer"  target="_blank" href="https://www.linkedin.com/company/olikaone-ltd" className="footer-light-text mt-3 mt-md-4">Linkedin</a>
                    </div>
                </div>
                <div className="order-md-1 order-sm-0">
                    <div className="position-relative d-flex justify-content-center overflow-hidden">
                        <img alt="footer" className="footer-rotate" src="/footer_logo.png"/>
                        <div className="footer-logo">
                             <img alt="f-logo" src="/logo.svg"/>
                        </div>
                    </div> 
                </div>
                <div className="d-flex mt-5 mt-md-0 item-left order-2">
                    <div className="d-flex flex-column">
                        <h6 className="footer-text">Headquarters</h6>
                        <div className="footer-light-text  mt-3 mt-md-4">Higbury and Islington, N5 </div>
                        <div className="footer-light-text">London, UK <span className="weather"><img alt="weather" src={`http://openweathermap.org/img/w/${icon}.png`}/>{weather}°</span> </div>
                    </div>
                </div>
            </div>
            <div className="mb-5 pb-5 mt-md-0 mt-5 item-left footer-copyrights">
            © Olikaone 2021  <a  href="/privacy" className="ms-2" onClick={privacy} > Privacy</a>

            </div >
          
        </div>
    )
}
